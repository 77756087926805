import RemoveAppointmenRequestDTO from '../../dtos/RemoveAppointmentRequestDTO';
import { api } from '../api';

export const deleteAppointment = async ({
  appointmentId,
  reason,
  reasonDescription,
}: RemoveAppointmenRequestDTO): Promise<number> => {
  const { status } = await api.delete(`/appointments/${appointmentId}`, {
    data: { reason, reasonDescription },
  });

  return status;
};
