import { AssociateSheetPatientRequestDTO } from '../../dtos/AssociateSheetPatientDTO';
import { Patient } from '../../entities/Patient';
import { api } from '../api';

export const associateSheet = async ({
  sheetId,
  patientId,
}: AssociateSheetPatientRequestDTO): Promise<Patient> => {
  const { data } = await api.post('/patients/sheet', { sheetId, patientId });

  return data;
};
