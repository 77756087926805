import { SendSheetRequestDTO } from '../../dtos/SendSheetRequestDTO';
import { api } from '../api';

export const sendSheet = async ({
  id,
  submissionId,
}: SendSheetRequestDTO): Promise<any> => {
  const { data } = await api.post('/patients/sheet/submission', {
    id,
    submissionId,
  });

  return data;
};
