import { api } from '../api';
import { Appointment } from '../../entities/Appointment';
import AppointmentStatusEnum from '../../enums/AppointmentStatusEnum';

interface GetAppointmentsByProfessionalBetweenDatesProps {
  professionalId: string;
  userType?: 'generic' | 'patient' | 'professional';
  startDate?: string;
  endDate?: string;
  status?: AppointmentStatusEnum;
}

export const getAppointmentsByProfessionalBetweenDates = async ({
  professionalId,
  userType,
  startDate,
  endDate,
  status,
}: GetAppointmentsByProfessionalBetweenDatesProps): Promise<Appointment[]> => {
  let result: Appointment[] = [];

  if (professionalId && userType === 'professional') {
    const { data } = await api.get(
      `/appointments/professional/${professionalId}`,
      {
        params: {
          startDate,
          endDate,
          status,
        },
      },
    );

    result = data;
  }

  return result;
};
