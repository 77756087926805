import styled, { css } from 'styled-components';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';

type GridArea = 'aa' | 'ce' | 'f1' | 'f2' | 'f3';

interface FieldProps {
  gridArea?: GridArea;
  direction?: 'row' | 'column';
}

interface ListCardProps {
  size: 'small' | 'large';
}

export const Container = styled.div`
  min-height: 500px;
  width: 100%;
  padding-right: 52px;

  > h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    color: #707683;
  }
`;

export const GridContent = styled.div`
  display: grid;
  grid-template: 1fr / repeat(14, 1fr);
  grid-template-areas: 'aa aa aa aa aa ce ce ce ce ce ce ce ce ce';

  grid-column-gap: 45px;
  grid-row-gap: 35px;

  margin-top: 28px;
  width: 100%;
  height: 100%;
`;

export const Field = styled.div<FieldProps>`
  grid-area: ${({ gridArea }) => gridArea};
  display: flex;
  flex-direction: column;

  ${({ direction }) =>
    direction &&
    direction === 'row' &&
    css`
      flex-direction: row;
      align-items: center;
    `};

  > label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #707683;

    letter-spacing: 0.01em;

    > span {
      color: ${({ theme }) => theme.colors.danger};
      font-size: 18px;
      font-weight: 600;
      line-height: 0.8;
    }
  }

  > div {
    margin-top: 4px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;

export const InputSelect = styled(InputSelectAlternative)`
  > div:first-child {
    border: 1px solid ${(props) => props.theme.colors.gray2};
    border-width: 1px !important;
  }

  > div .react-select__control {
    border-width: 1px;
  }
`;

export const SpecializationsContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  background: #f8f8f8;
  border-radius: 6px;
  padding: 35px 30px;

  h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    color: #707683;
    margin-bottom: 40px;
  }

  h2 {
    letter-spacing: 4px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    color: #707683;
    margin-top: 40px;
  }

  form {
    > div {
      display: grid;
      grid-template: 1fr / repeat(14, 1fr);
      grid-template-areas: 'f1 f1 f1 f1 f1 f1 f1 f1 f2 f2 f2 f2 f2 f2';
      grid-column-gap: 45px;
      grid-row-gap: 35px;
      margin-top: 25px;

      &:last-child {
        margin-bottom: none;
      }
    }

    button {
      width: 100%;
      max-width: 200px;
      font-weight: bold;
      margin-top: 25px;
    }
  }
`;

export const List = styled.ul`
  li {
    display: flex;
    align-items: center;

    margin-top: 15px;

    > button {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 25px;
      width: 25px;
      border: 2px solid #d5d5d5;
      background: #fff;
      margin-left: 25px;
      border-radius: 100px;
      position: relative;

      &:disabled {
        div {
          height: 15px;
          width: 15px;
          position: absolute;
          background: ${({ theme }) => theme.colors.primary};
          border-radius: 100px;
        }
      }
    }

    > span {
      font-weight: 500;
      font-size: 14px;
      margin-left: 10px;
      color: ${({ theme }) => theme.colors.graySecondary};
    }
  }
`;

export const ListCard = styled.div<ListCardProps>`
  display: flex;
  align-items: center;

  height: 45px;
  width: 100%;
  max-width: ${({ size }) => (size === 'small' ? '300px' : '550px')};
  padding: 0 15px;
  background: rgba(45, 156, 219, 0.25);
  border-radius: 6px;

  > div {
    display: flex;
    align-items: center;
    flex: 1;

    span {
      font-weight: 500;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.graySecondary};

      b {
        font-weight: bold;
      }
    }

    div {
      height: 20px;
      width: 2px;
      background: ${({ theme }) => theme.colors.graySecondary};
      margin: 0 13px;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
    border: none;
    margin-left: 20px;
  }
`;
